@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  body {
    @apply
    min-h-screen
    bg-zinc-800
    grid
  }

  .baseText {
    @apply
    text-white text-base
  }

  .baseBtn {
    @apply
    bg-blue-500 font-bold rounded baseText
  }

  .standardBtn {
    @apply
    hover:bg-blue-700 py-1.5 px-3
  }

  .scoreText {
    @apply
    font-extrabold baseText
  }

  .scoreUnderline { 
    @apply
    underline underline-offset-2
  }

  .mapSize {
    @apply
    w-140
  }
}